import React from 'react'
import { Tweet } from 'react-tweet'

const Latest = () => {

  return (
    <div id='latest' className='w-full flex flex-col gap-[3rem] lg:pt-[5rem] pb-[10rem] '>
      <h1 className="text-[1.25rem] text-[#ffffff] font-semibold font-montserrat tracking-[15%] w-full text-center ">Elon's Starship Tweets</h1>
      <div className="flex flex-col items-center gap-[5rem] lg:gap-[8rem] ">

        <Tweet id={'1728215732784382087'} />

      </div>
<button
  className="text-[1.25rem] text-[#ffffff] h-[2.8rem] w-full max-w-[15rem] mx-auto bg-tertiary rounded-[8px] hover:text-[#ffc8c5] active:text-[#4b0804] shadow-[2px_4px_25px_10px_rgba(255,108,100,0.25)] transition-all duration-200 ease-in-out"
  onClick={() => window.open('https://twitter.com/search?q=Starship%20(Starship)%20(from%3Aelonmusk)%20-filter%3Areplies&src=typed_query&f=live', '_blank')}
>
  More Elon's Tweets
</button>

    </div>
  )
}

export default Latest