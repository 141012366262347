import React from 'react'

const Video = () => {
  return (
    <div className='w-full flex flex-col gap-[3rem] pb-[5rem] '>

      <iframe
        src='https://www.youtube.com/embed/CMGiNKcVSek'
        title='StarSHIP Launch'
        allow='accelerometer; gyroscope;'
        allowFullScreen
        className='mx-auto max-w-[40rem] w-[90%] h-screen max-h-[25rem]'
      ></iframe>
    </div>
  )
}




export default Video